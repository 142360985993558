<!--
File: Report_10_Section_wise_AADT.vue
Description: produce report showing Region/DEU-wise Condition index.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-15">
          <BaseDropdown :label="$t('label.select_region')" v-model="selectedRegion" :items="regionsInReport10"
            :displayField="'name'" :valueField="'id'" />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-15">
          <BaseDropdown :label="$t('label.select_road')" v-model="selectedRoad" :items="roadsList"
            :displayField="'name'" :valueField="'id'" :disabled='!selectedRegion' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-15">
          <BaseDropdown :label="$t('road_network.survey_year')" v-model="selectedYear" :items="yearsInReport10"
            :displayField="'year'" :valueField="'year'" />
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="print" :disabled="btnDisabled || emptyData">
            {{ $t('buttons.print') }}
          </md-button>
          <md-button class="md-raised md-success" @click="exportToExcel" :disabled="btnDisabled">
            {{ $t('buttons.excel_export') }}
          </md-button>
        </div>
      </div>
    </md-card-header>
    <md-card-content>
      <md-progress-spinner v-if="showSpinner == true" :md-diameter="70" md-mode="indeterminate" />
      <md-table class="paginated-table table-striped table-hover" :value="report" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell style="max-width: 200px;" :md-label="$t('road_network.section')"
            md-sort-by="section_description">
            {{ item.section_description }}
          </md-table-cell>
          <md-table-cell style="max-width: 200px;" :md-label="$t('road_network.survey_year')" md-sort-by="survey_year"
            md-numeric>
            {{ item.survey_year }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.aadt_a1_spc')" md-sort-by="aadt_a1_spc" md-numeric>
            {{ item.aadt_a1_spc | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.aadt_b1_mb')" md-sort-by="aadt_b1_mb" md-numeric>
            {{ item.aadt_b1_mb | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.aadt_b2_l2tr')" md-sort-by="aadt_b2_l2tr" md-numeric>
            {{ item.aadt_b2_l2tr | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.aadt_b3_tractors')" md-sort-by="aadt_b3_tractors" md-numeric>
            {{ item.aadt_b3_tractors | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.aadt_c1_m2tr')" md-sort-by="aadt_c1_m2tr" md-numeric>
            {{ item.aadt_c1_m2tr | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.aadt_c2_h3tr')" md-sort-by="aadt_c2_h3tr" md-numeric>
            {{ item.aadt_c2_h3tr | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.aadt_d1_buses')" md-sort-by="aadt_d1_buses" md-numeric>
            {{ item.aadt_d1_buses | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.aadt_d2_truck_tr')" md-sort-by="aadt_d2_truck_tr" md-numeric>
            {{ item.aadt_d2_truck_tr | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.aadt_d3_ar4tr_tr')" md-sort-by="aadt_d3_ar4tr_tr" md-numeric>
            {{ item.aadt_d3_ar4tr_tr | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.aadt_total')" md-sort-by="aadt_total" md-numeric>
            {{ item.aadt_total | numFormat }}
          </md-table-cell>
        </md-table-row>
      </md-table>
    </md-card-content>
  </md-card>
</template>
<script>
import BaseDropdown from '../Dropdowns/BaseDropdown.vue'
import { mapGetters } from 'vuex'
import { numFormat } from "@/store/refdata/format_helpers"
import { customSortMixin } from '@/mixins/customSortMixin'
import printReport from '@/mixins/printMixin'
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const baseColumns = [
  { header: 'road_network.section', key: 'section_description', width: 40 },
  { header: 'traffic.aadt_a1_spc', key: 'aadt_a1_spc', width: 10, num: true, digits: 2 },
  { header: 'traffic.aadt_b1_mb', key: 'aadt_b1_mb', width: 10, num: true, digits: 2 },
  { header: 'traffic.aadt_b2_l2tr', key: 'aadt_b2_l2tr', width: 10, num: true, digits: 2 },
  { header: 'traffic.aadt_b3_tractors', key: 'aadt_b3_tractors', width: 10, num: true, digits: 2 },
  { header: 'traffic.aadt_c1_m2tr', key: 'aadt_c1_m2tr', width: 10, num: true, digits: 2 },
  { header: 'traffic.aadt_c2_h3tr', key: 'aadt_c2_h3tr', width: 10, num: true, digits: 2 },
  { header: 'traffic.aadt_d1_buses', key: 'aadt_d1_buses', width: 10, num: true, digits: 2 },
  { header: 'traffic.aadt_d2_truck_tr', key: 'aadt_d2_truck_tr', width: 10, num: true, digits: 2 },
  { header: 'traffic.aadt_d3_ar4tr_tr', key: 'aadt_d3_ar4tr_tr', width: 10, num: true, digits: 2 },
  { header: 'traffic.aadt_total', key: 'aadt_total', width: 10, num: true, digits: 2 },
];

export default {
  mixins: [customSortMixin, printReport],
  name: 'report-section_wise_aadt',
  data() {
    return {
      selectedRegion: null,
      selectedRoad: null,
      selectedYear: null,
      btnDisabled: true,

      showSpinner: false,
      currentSort: 'section_description',
      currentSortOrder: 'asc',
    }
  },
  components: {
    BaseDropdown,
  },

  mounted() {
    this.reloadData()
    if (this.yearsInReport10.length > 0) this.selectedYear = this.yearsInReport10[this.yearsInReport10.length - 1].year
  },

  methods: {
    async reloadData() {
      this.showSpinner = true
      this.btnDisabled = true
      await this.$store.dispatch('REPORT_10_SECTION_WISE_AADT')
      this.btnDisabled = false
      this.showSpinner = false
    },

    print() {
      this.showSpinner = true
      this.printReport(this.getPrintHeader, this.getTableHeaders, this.getTableRows)
      this.showSpinner = false
    },

    setWorkSheetHeader(worksheet) {
      let titleRow = worksheet.insertRow(1, [this.$t('label.reports_title')])
      titleRow.font = { bold: true, size: 16 };
      titleRow.alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.mergeCells('A1:K1');

      titleRow = worksheet.insertRow(2, [`${this.$t('label.report10_title')}`]);
      titleRow.font = { size: 14 };
      titleRow.alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.mergeCells('A2:K2');

      if (this.headerTitles.length > 0) {
        titleRow = worksheet.insertRow(3, [this.headerTitles.join(', ')]);
        titleRow.font = { size: 14 };
        titleRow.alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.mergeCells('A3:K3');
      }
    },

    fillWorkSheet(workbook, workbookName) {
      const headerCellFill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF0070C0' } } // Blue
      const worksheet = workbook.addWorksheet(workbookName);

      // Set the table headers
      const columns = this.getWorksheetColumns
      worksheet.columns = columns

      // Apply formatting to the table headers first  row
      let newRow = worksheet.getRow(1);
      newRow.font = { bold: true, color: { argb: 'FFFFFFFF' } }
      newRow.fill = headerCellFill
      newRow.alignment = { vertical: 'middle', horizontal: 'center' }

      // Number formatting for the columns
      columns.forEach(item => {
        if (item.num) worksheet.getColumn(item.key).numFmt = item.digits == 3 ? '#,##0.000' : '#,##0.00'
      })

      this.report.forEach(item => {
        worksheet.addRow(item);
      });

      // Set the worksheet header, should be after filling the table
      this.setWorkSheetHeader(worksheet)
    },

    async exportToExcel() {
      // Create new Excel file
      const workbook = new ExcelJS.Workbook();
      const wbName = this.$t('label.report10_title')
      this.fillWorkSheet(workbook, wbName)

      // Save
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${wbName}.xlsx`);
    },

  },

  computed: {
    ...mapGetters(['report10_filtered', 'regionsInReport10', 'roadsInReport10', 'yearsInReport10']),

    report() {
      return this.report10_filtered(this.selectedRegion, this.selectedRoad, this.selectedYear)
    },

    roadsList() {
      return this.roadsInReport10(this.selectedRegion)
    },

    emptyData() {
      return this.report.length == 0
    },

    getWorksheetColumns() {
      const res = baseColumns.map(item => {
        return {
          ...item,
          header: this.$t(item.header)
        }
      });
      return res
    },

    headerTitles() {
      const titleFiltersArray = [
        { condition: this.selectedRegion, column: this.$t('label.from_region', { name: this.report[0].region_description }) },
        { condition: this.selectedRoad, column: `${this.$t('road_network.road')}: ${this.report[0].road_description}` },
        { condition: this.selectedYear, column: `${this.$t('label.as_on')} ${this.selectedYear}` },
      ].filter(item => item.condition).map(item => item.column);

      return titleFiltersArray;
    },

    getPrintHeader() {
      const titleFilters = this.headerTitles.length > 0 ? `<br>(${this.headerTitles.join(', &nbsp;')})` : '';

      return `<h4 style='text-align:center'>${this.$t('label.reports_title')}</h4>
        <h4 style='text-align:center'>
          ${this.$t('label.report10_title')}
          ${titleFilters}
        </h4>`;
    },

    getTableRows() {
      const columns = this.getWorksheetColumns

      const tableRowsArray = this.report.map(item => {
        return `<tr>${columns.map(col => col.num
          ? `<td class='numCell'>${numFormat(item[col.key], col.digits)}</td>`
          : `<td>${item[col.key]}</td>`).join('')}
        </tr>`;
      });

      return tableRowsArray.join('');
    },
  },

  watch: {
    selectedRegion() {
      this.selectedRoad = null
    }
  }
}
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

::v-deep .md-table-head-label {
  font-size: 13px !important;
  overflow: visible !important;
  white-space: normal !important;
  text-align: center !important;
  word-wrap: break-word !important;
}

.paginated-table table>tbody>tr>td {
  font-size: 12px !important;
  height: 36px;
  padding: 0px !important;
  padding-right: 24px !important;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>